body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  bottom: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-logo {
  margin-left: 50px;
  width: 100px;
}
.nav-logo img {
  height: 80px;
  float: left;
  top: 0;
  position: fixed;
  z-index: 2000;
}

header {
  background-image: url(./img/header-bg.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: #fff;
  height: 700px;
}

.header-container {
  width: 270px;
  margin-left: 10%;
  padding-top: 100px;
  display: block;
  position: relative;
}

.header-description {
  padding: 20px;
  background: rgba(0, 0, 0, 0.4);
}

.content-container {
  margin: 0px;
  padding-top: 20px;
  clear: both;
  display: block;
  position: relative;
}
.content-runners,
.content-rules-header {
  margin-top: 50px;
  text-align: center;
}

.content {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

body {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #eee;
}

.content p {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 0px;
}

.content img {
  width: 90%;
  margin: 10px;
}

.content ul {
  text-align: left;
  margin-left: 100px;
}

@media only screen and (max-width: 960px) {
  nav .nav-container {
    width: 100%;
  }

  .content {
    width: 100%;
  }
}

@media only screen and (min-width: 960px) {
  nav .nav-container {
    width: 900px;
  }

  .content {
    width: 900px;
  }
}

table {
  border-spacing: 0;
  border: 1px solid #ccc;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}

.navbar-collapse {
  text-align: center;
}
